import axios from "axios";
/**
 *  Local  : "http://192.167.0.83:90/"

 * */

/***Reading baseUrl from config.json
 * 
 */
export default class APIHandler {

    static AxiosInstance;

    init() {

        return new Promise((resolve, reject) => {

            axios.get('config.json').then((res) => {

                APIHandler.AxiosInstance = axios.create({
                     baseURL: res.data.baseURL
                });
                resolve();
            });
        })
    }
}


