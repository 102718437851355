const Strings = {
    /**
     * API 
     */
    WEB_API: {

        WEB_CHECK_IPORIGIN_COUNTRY: "api/user/checkIpOriginCountry",
        WEB_CHECK_IPFOR_GLOBALSITES: "api/user/checkIfIpFromRestrictedCountry",
        WEB_GET_DATAFROM_URL: "api/user/getDataByUid",

    },
    /**
     *  Constants
     */
    CONSTANTS: {
        AUTH_TOKEN: "AuthToken",
        USER_ID: "USER_ID",
        LOGIN_FAILURE_COUNT: "LOGIN_FAILURE_COUNT",

        DEFAULT_TOKEN_QRCODE: "webtoken1235",
        DEFAULT_QRCODE_PASSWORD: "0000"
    },

    /**
     * Url Strings
     */
    URL_STRING: {

        HOMEPAGE: "/",
        QRCODE_URL: "/:uid",
        ACCESS_DENIED: "/accessdenied",
        SERVICE_NOT_AVAILABLE: "/notavilable"
    },

    /**
     * Error Codes
     */
    ERROR_CODE: {

        USER_NOT_EXIST: 6003,
        INCORRECT_PASSWORD: 16003,
        SERVICE_NOT_AVAILABLE: 503
    },
    BLOB_CDN_URL: {
        BASE_PATH: "https://liplay-cdn.instax.com/instaxstoragecontainer",
        CONTAINER: "instaxstoragecontainer",
      }

};

export default Strings;