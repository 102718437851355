import React, { Component } from 'react';
import { Button } from 'antd';
import '../css/common.css'
import '../css/VideoDownloadExceededInfo.css'
var Translate = require('react-redux-i18n').Translate;
var imgIconFailed = require('./../assets/download_error.svg');

class VideoDownloadExceededInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            titleText: "Title",
            subtitleText: "Subtitle",
            dismissButtonText: "Back"
        }
    }

    componentWillReceiveProps() {
        this.setDownloadExceededTexts();
    }

    setDownloadExceededTexts() {
        this.setState({
            dismissButtonText: <Translate value="BACK_BUTTON_TEXT_DOWNLOAD_EXCEEDED" />,
            titleText: <Translate value="TITLE_DOWNLOAD_EXCEEDED" />,
            subtitleText: <Translate value="SUBTITLE_DOWNLOAD_EXCEEDED" />
        });
    }

    render() {
        const { titleText, subtitleText, dismissButtonText } = this.state
        const { isVisible, topValueOfDownloadButton, topValueOfCenterContent, dismissBtnStyle,
            handleBackBtn } = this.props

        return (
            <div className="downloadStatusDiv" style={{ display: `${isVisible ? '' : 'none'}` }}>
                <div className="infoStatusDiv center-content"
                    style={{ top: topValueOfCenterContent, margin: "auto", textAlign: "center" }}>
                    <img className="imgInfoIcon" id="" alt="" src={imgIconFailed} />
                    <span className="downloadStatus-txt">
                        <h4 className="title-txt is-white">{titleText}</h4>
                        <h4 className="subtitle-txt is-white">{subtitleText}</h4>
                    </span>
                </div>
                <div className="dismissBtnDiv" style={{ top: topValueOfDownloadButton }}>
                    <Button className="dismissBtn" style={dismissBtnStyle} onClick={handleBackBtn}> <span>{dismissButtonText}</span></Button>
                </div>
            </div >
        )
    }
}
export default VideoDownloadExceededInfo;