import React, { Component } from "react"
import { Modal, Button } from "antd"
import "../css/ModalAppRequiredInfo.css"
import chinaAppIcon from "../assets/china_ic_apprequired.png"
import globalAppIcon from "../assets/global_ic_apprequired.png"
import iconClose from "../assets/icon_close.png"
var Translate = require('react-redux-i18n').Translate;

class ModalAppRequiredInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            iconLiplayApp: globalAppIcon,
            appRequiredInfoMsg: ""
        }
    }

    componentDidMount() {

        if (this.props.isChinaWebApp) {
            this.setState({
                iconLiplayApp: chinaAppIcon,
                appRequiredInfoMsg: <Translate value="APP_REQUIRED_INFO_CN" />
            })
        }
        else {
            this.setState({
                iconLiplayApp: globalAppIcon,
                appRequiredInfoMsg: <Translate value="APP_REQUIRED_INFO" />
            })
        }
    }

    render() {
        const { isVisible, handleOk, handleCancel } = this.props;
        const { appRequiredInfoMsg, iconLiplayApp } = this.state;
        return (

            < div >
                <Modal
                    visible={isVisible}
                    footer={null}/**for avoiding footer line */
                    title={
                        <div>
                            <img src={iconClose} onClick={handleCancel} className="app-required-close-btn" alt="" />
                            <div className="app-required-description"> {appRequiredInfoMsg}</div>
                            <div className="logoOuterDiv">
                                <div className="logoInnerDiv">
                                    <img className="instaxLogoImage" src={iconLiplayApp} alt="" />
                                </div>
                            </div>
                            <Button onClick={handleOk} className="app-required-ok-button"><Translate value="APP_REQUIRED_BTN_OK" /></Button>
                        </div>
                    }
                    closable={false} /**for avoiding default close-btn */
                    maskClosable={false}
                    centered /** it makes the modal vertically center. */
                    className="modal-app-required"
                />
            </div >
        )
    }
}

export default ModalAppRequiredInfo;