import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import QRCodeData from './layout/QRCodeData';
import APIHandler from './network/AxiosInstance';
import GlobalStrings from "./network/Global";
import Home from './layout/Home';
import UnAuthorizedAcesss from './layout/UnAuthorizedAccess';

/**
 * Class App Routes to All Components.
 */
class App extends Component {

  constructor(props) {
    super(props);
    this.state = { initialiseApiHandler: false }

  }

  componentDidMount() {
    new APIHandler().init().then(() => {

      this.setState({ initialiseApiHandler: true });
    })
  }

  render() {
    if (this.state.initialiseApiHandler) {

      return (

        <div>

          <Switch>
            <Route exact path={GlobalStrings.URL_STRING.ACCESS_DENIED} name="UnAuthorized Acesss Page" component={UnAuthorizedAcesss} />
            <Route exact path={GlobalStrings.URL_STRING.HOMEPAGE} name="Home Page" component={Home} />
            <Route path={GlobalStrings.URL_STRING.QRCODE_URL} name="Get Data" component={QRCodeData} />
          </Switch>

        </div >

      );
    } else {
      return (
        <div>
        </div>
      );
    }
  }
}

export default withRouter(App);
