// ***********************************************************************
// Assembly         : FujiFilm.InstaxMini.UserWeb
// Author           : Pranav K
// Created          : 05-11-2018
// Modified On : 14-03-2020
// ***********************************************************************
// <copyright file="QrCodeData.js" company="Fuji Film Corporation">
//     Copyright ©  2018
// </copyright>
// <summary></summary>
// ***********************************************************************
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import GlobalStrings from "../network/Global";
import LangStrings from "../network/Lang";
import APIHandler from '../network/AxiosInstance';
import { sha256 } from 'js-sha256';
// eslint-disable-next-line
import '../css/qrcodedata.css';
import axios from 'axios';
import { Input, Alert, Form, Modal, Spin } from 'antd';
import { isIOS, isAndroid, browserName, browserVersion, fullBrowserVersion, isOpera, isChrome,isFirefox } from "react-device-detect";
import ModalAppRequiredInfo from '../components/ModalAppRequiredInfo';
import VideoDownloadExceededInfo from '../components/VideoDownloadExceededInfo';
import DownloadBtnComponent from '../components/DownloadBtnComponent';
import ServiceNotAvilable from './ServiceNotAvilable';
var iconWebPlayButtonNormal = require('./../assets/icon_web_button_normal.png');
var iconWebPlayButtonPressed = require('./../assets/icon_web_button_pressed.png')
var iconWeblogoNormal = require('./../assets/icon_web_logo_normal.png');
var iconWebBaseImage = require('./../assets/icon_web_base.svg');
var imgNoImageInfo = require('./../assets/noImage.jpg');
var imgNoImageInfoIos = require('./../assets/noImage_iOS.jpg');

const FormItem = Form.Item;
var Translate = require('react-redux-i18n').Translate;

const normalImageDivstyle = {
    msTransform: "rotate(90deg)",
    WebkitTransform: " rotate(90deg)",
    transform: "rotate(90deg)"
}

const iosImageDescStyle = {
    paddingTop: "17%",
    paddingRight: "7%",
    paddingLeft: "10%",
    flex: 1, justifyContent: "center",
    display: "flex"
}

const normalImageDescStyle = {
    paddingRight: "7%",
    paddingLeft: "10%",
    paddingTop: "17%",
    flex: 1,
    justifyContent: "center",
    display: "flex"
}

const VIDEO_DOWNLOAD = {
    STATUS_FLAG: {
        DISABLED: 100,
        ENABLED: 101,
        DEFAULT_OR_NOT_DEFINED: 0
    }
}

const navigatorUserAgent = navigator.userAgent;
const isSamsungBrowser = navigatorUserAgent.includes("SamsungBrowser"); //Checking for Samsung browser

class QrCodeData extends Component {

    constructor(props) {
        super(props);
        this.downloadBtnRef = React.createRef();
        this.state = {
            Uid: this.props.match.params.uid,
            imageUrl: null,
            audioUrl: "null",
            dataExpiryDate: "",
            loading: true,
            showSoundbtn: "",
            showNoImageInfo: 'none',
            showMainContainer: "none",
            showMainContainerLoading: "",
            showPlaying: "none",
            playFromPosition: 0,
            visible: false,
            imageExist: true,
            web_play_btn_icon: iconWebPlayButtonNormal,
            web_icon_logo_instax: iconWeblogoNormal,
            loading_passwordImage: false,
            modalErrorMessage: "",
            modalLoginFailureCount: 0,
            showRemainingAttempts: "none",
            showWebPlayBtnIcon: "",
            soundCheckiPasswordProtect: false,
            verifiedIp: false,
            soundIconPressedState: false,
            isVideoDownloadButtonVisible: "none",
            isVideoButtonDisabled: false,
            isVideoDownloadCountExceeded: false,
            isVideoDownloadExceedWarningVisible: false,
            isVideoDownloadSupportedPlatform: true,
            isAppRequiredModalVisible: false,
            topValueDownloadButtonOverlay: 0,
            topValueCenterContentOverlay: 0,
            downloadExceededDismissBtnStyle: null,
            firebaseDynamicLink: null,
            isChinaConfig: false,
            isProductionBuild: true,
            isServiceDown: false
        }

    }

    componentWillMount() {
        axios.get('config.json').then((res) => {
            var countrywiseRestrictionChina = res.data.countrywiseRestrictionInChina;
            var countrywiseRestrictionGlobal = res.data.countrywiseRestrictionInGlobal;
            var soundPasswordProtect = res.data.soundCheckiPasswordProtect;
            var firebaseDynamicLinkForVideoDownload = res.data.firebaseDynamicLink;
            var isChinaBuild = res.data.isChinaBuild;
            var isProductionBuild = res.data.isProductionBuild;
            // eslint-disable-next-line
            if (soundPasswordProtect == "true") {
                soundPasswordProtect = true
            } else {
                soundPasswordProtect = false
            }
            this.setState({
                firebaseDynamicLink: firebaseDynamicLinkForVideoDownload,
                isChinaConfig: isChinaBuild,
                soundCheckiPasswordProtect: soundPasswordProtect,
                isProductionBuild : isProductionBuild
            })
            // eslint-disable-next-line
            if (countrywiseRestrictionChina == "true") {
                //China Deploy
                this.handleCountrywiseRestrictionForChina();
            }
            // eslint-disable-next-line
            else if (countrywiseRestrictionGlobal == "true") {
                //Global Deploy
                this.handleCountryRestrictionForGlobal();
            }
            else {
                this.setState({ verifiedIp: true });
                this.handleAppStart();
            }
        });

    }

    componentDidMount = () => {
        // update the dimensions when browser resize event triggers.
        if(!this.state.isServiceDown){
        window.addEventListener('resize', this.updateDimensions);
    }
    }

    updateDimensions = () => {
        if(!this.state.isServiceDown){
            this.setVideoDownloadExceededInfoStyle()
        }       
    };

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    /**
     * Countrywise Restriction Part of China
     */
    handleCountrywiseRestrictionForChina() {

        APIHandler.AxiosInstance.post(GlobalStrings.WEB_API.WEB_CHECK_IPORIGIN_COUNTRY)
            .then(response => {
                console.log(response);
                if (response.data.status === 0) {
                    this.setState({ verifiedIp: true });
                    this.handleAppStart();
                }

                else {
                    this.setState({ verifiedIp: false })
                    this.props.history.push(GlobalStrings.URL_STRING.ACCESS_DENIED);
                }
            });
    }

    /**
     * Handle Countrywise(China-country) restriction For Global
     */
    handleCountryRestrictionForGlobal() {
        APIHandler.AxiosInstance.post(GlobalStrings.WEB_API.WEB_CHECK_IPFOR_GLOBALSITES)
            .then(response => {

                console.log(response);
                if (response.data.status === 0) {
                    this.setState({ verifiedIp: true });
                    this.handleAppStart();
                }
                else {
                    this.setState({ verifiedIp: false })
                    this.props.history.push(GlobalStrings.URL_STRING.ACCESS_DENIED);
                }
            });
    }

    /**
     * Start Call After  Verification
     * Check If Passwordpromt Needed
     */
    handleAppStart() {
        const { soundCheckiPasswordProtect } = this.state;

        if (soundCheckiPasswordProtect) {
            this.setState({
                showMainContainerLoading: "none",
                visible: true
            });
        } else {
            this.handleGetQrCodeData(GlobalStrings.CONSTANTS.DEFAULT_QRCODE_PASSWORD);
        }
        this.handleLocalStorageExpiry();
    }

    handleLocalStorageExpiry = () => {
        //check if past expiration date
        if (localStorage.getItem(GlobalStrings.CONSTANTS.LOGIN_FAILURE_COUNT) != null) {
            var value = localStorage.getItem(GlobalStrings.CONSTANTS.LOGIN_FAILURE_COUNT).split(";");

            if (new Date(value[1]) < new Date()) {
                localStorage.removeItem(GlobalStrings.CONSTANTS.LOGIN_FAILURE_COUNT);
            }
        }
    }

    handleSetFailureCount = (count) => {
        var values = [];
        var oneday = new Date();
        oneday.setMinutes(oneday.getMinutes() + 1); //one min from now
        values.push(count);
        values.push(oneday);
        try {
            localStorage.setItem(GlobalStrings.CONSTANTS.LOGIN_FAILURE_COUNT, values.join(";"));
        }
        catch (e) { }

    }

    /**For mapping blob url to global CDN Url  */
    mapToBlobCdnUrl = (blobUrl) => {
        return (
            GlobalStrings.BLOB_CDN_URL.BASE_PATH +
            blobUrl.split(GlobalStrings.BLOB_CDN_URL.CONTAINER)[1]
        );
    };

    handleGetQrCodeData = (password) => {
        //Checking login failure count.
        this.handleLocalStorageExpiry();
        var loginFailureCount = localStorage.getItem(GlobalStrings.CONSTANTS.LOGIN_FAILURE_COUNT);
        if (loginFailureCount == null) {
            this.handleSetFailureCount('0');
        }
        var failCount = localStorage.getItem(GlobalStrings.CONSTANTS.LOGIN_FAILURE_COUNT).split(";");
        var countInt = parseInt(failCount[0], 10) + 1;
        if (countInt > 5) {
            this.setState({
                modalErrorMessage: <Translate value="MAX_TRY_EXCEEDED" />,
                showRemainingAttempts: "none"
            });
            return;
        }

        //Password hashing using sha256
        var hash = sha256.create();
        hash.update(password);
        var hashedPassword = hash.hex();

        let config = {
            headers: {
                Authorization: 'Bearer ' + GlobalStrings.CONSTANTS.DEFAULT_TOKEN_QRCODE,
            }
        }
        this.setState({
            showMainContainerLoading: "",
            loading_passwordImage: true,
            modalErrorMessage: "",
            showRemainingAttempts: "none"
        });

        //Handling API response.
        APIHandler.AxiosInstance.post(GlobalStrings.WEB_API.WEB_GET_DATAFROM_URL,
            { uId: this.state.Uid, password: hashedPassword }, config)
            .then(response => {
                console.log(response)
                this.setState({ loading_passwordImage: false })
                if (response.data.data) {
                    var dateTime = response.data.data.expirationDate;
                    var date = dateTime.split("T")[0];
                    // var cdnImageUrl = "https://api.blob.instax.com/instaxstoragecontainer"
                    //     + response.data.data.imageUrl.split("instaxstoragecontainer")[1];
                    // var cdnAudioUrl = "https://api.blob.instax.com/instaxstoragecontainer"
                    //     + response.data.data.audioUrl.split("instaxstoragecontainer")[1];
                    var cdnImageUrl = (!this.state.isChinaConfig && this.state.isProductionBuild )? this.mapToBlobCdnUrl(response.data.data.imageUrl) : response.data.data.imageUrl
                    var cdnAudioUrl = (!this.state.isChinaConfig && this.state.isProductionBuild )? this.mapToBlobCdnUrl(response.data.data.audioUrl) : response.data.data.audioUrl
                    this.checkVideoDownloadAllowed(response.data.data.videoDownloadFlag);
                    this.setState({
                        imageUrl: cdnImageUrl,
                        audioUrl: cdnAudioUrl,
                        dataExpiryDate: date,
                        loading: false,
                        imageExist: true,
                        showSoundbtn: '',
                        isVideoDownloadCountExceeded: response.data.data.isVideoDownloadCountExceeded
                    });

                    this.handleCancel();
                    this.handleSetFailureCount('0');
                }

                else if (response.data.status === GlobalStrings.ERROR_CODE.INCORRECT_PASSWORD) {
                    const form = this.formRef.props.form;
                    form.setFieldsValue({ 'Password': "" });

                    var failureCount = localStorage.getItem(GlobalStrings.CONSTANTS.LOGIN_FAILURE_COUNT).split(";");
                    var count = parseInt(failureCount[0], 10) + 1;

                    this.handleSetFailureCount(count);

                    if (count >= 5) {
                        this.setState({
                            modalErrorMessage: <Translate value="MAX_TRY_EXCEEDED" />,
                            showRemainingAttempts: "none"
                        });
                        return;
                    } else {
                        this.setState({
                            modalErrorMessage: <Translate value="INCORRECTPASSWORD" />,
                            modalLoginFailureCount: count,
                            showRemainingAttempts: ""
                        });
                    }
                }
                else if(response.data.status === GlobalStrings.ERROR_CODE.SERVICE_NOT_AVAILABLE){
                    this.setState(currentState => ({
                        ...currentState,
                        isServiceDown: true
                      }));
                }
                else {
                    this.setState({
                        showSoundbtn: 'none',
                        showNoImageInfo: ' ',
                        loading: false,
                        imageExist: false,
                        visible: false,
                        imageUrl: imgNoImageInfo
                    });
                }
            });
    }

    /**
     * This for checking video download permission is granted or not.
     */
    checkVideoDownloadAllowed = (videoDownloadFlag) => {

        //check the video status flag for enabling or disabling the download button.
        // eslint-disable-next-line
        if (videoDownloadFlag == VIDEO_DOWNLOAD.STATUS_FLAG.ENABLED) {
            this.setState({ isVideoDownloadButtonVisible: "" })
        } else {
            this.setState({ isVideoDownloadButtonVisible: "none" })
        }
    }


    /**
     * Image Loaded Call-back  from img tag.
     */
    handleImageLoaded = () => {
        this.setState({
            showMainContainerLoading: "none",
            showMainContainer: ""
        })

        //For getting dimesions after loading the image.
        setTimeout(() => {
            this.setVideoDownloadExceededInfoStyle();
        }, 500);
    }

    /**
    * For Playing Audio
    */
    playSound = () => {

        var sound = document.getElementById("id_audio");
    
        if (this.state.soundIconPressedState) {

            /**Play Sound */
            sound.play();

            /**Sound started playing */
            sound.onplaying = () => {
                this.setState({
                    showPlaying: "",
                    showWebPlayBtnIcon: "none",
                    soundIconPressedState: false
                });
            };
            
            /**Sound stopped playing */
            sound.onended = () => {
                this.handleSoundFinishedPlaying();
            }
        }
    }

    handleOntouchStartPlayBtn = () => {
        this.setState({
            web_play_btn_icon: iconWebPlayButtonPressed,
            web_icon_logo_instax: require('./../assets/icon_web_logo_pressed.png'),
            soundIconPressedState: true
        });
        //Allow only 5 Seconds for Pressed State
        setTimeout(this.handleResetSound, 5000)
    }

    /**
     * Reset Sound 
     */
    handleResetSound = () => {

        var sound = document.getElementById("id_audio");
        if (sound.duration <= 0 || sound.paused) {
            this.setState({
                showPlaying: "none",
                showWebPlayBtnIcon: "",
                soundIconPressedState: false,
                web_play_btn_icon: iconWebPlayButtonNormal,
                web_icon_logo_instax: require('./../assets/icon_web_logo_normal.png')
            });
        }
    }

    /**
     * Sound Finished Callback -react-sound
     */
    handleSoundFinishedPlaying = () => {

        this.setState({
            showPlaying: "none",
            soundIconPressedState: false,
            showWebPlayBtnIcon: "",
            web_play_btn_icon: iconWebPlayButtonNormal,
            web_icon_logo_instax: require('./../assets/icon_web_logo_normal.png')
        });
    }

    /***
     * Used to render Image Division
     * Styling used in Chrome(Ver.>88) and Opera uses-
     * same styling as iOS devices. (for image exif info.)
    */
    renderImageDivision = () => {
        const { showNoImageInfo, imageExist, web_play_btn_icon,
            showSoundbtn, showWebPlayBtnIcon, dataExpiryDate } = this.state;
        var setImageUrl = imgNoImageInfoIos;
       
        if (isIOS || isOpera || isFirefox || (isSamsungBrowser && fullBrowserVersion >= 13) || (isChrome && fullBrowserVersion > 88)  ) {
        console.log("iOS Device or greater than Chrome 88 or Opera");
            if (imageExist) {
                setImageUrl = this.state.imageUrl;
            }
            return <div>
                <div className="picpropIos" >
                    <img className="baseImageIos" src={iconWebBaseImage} alt="" />
                    <img className="imagePropertyIos" id="" alt=""
                        onLoad={this.handleImageLoaded.bind(this)} src={setImageUrl} />
                    <span style={{ display: `${showSoundbtn}` }}
                        onTouchStart={()=>this.handleOntouchStartPlayBtn()}
                        onTouchEnd={()=>this.playSound()}
                        onTouchMove={event => event.preventDefault()}
                        onMouseDown={()=>this.handleOntouchStartPlayBtn()}
                        onMouseUp={()=>this.playSound()}
                    > <i>
                            <img style={{ display: `${showWebPlayBtnIcon}` }}
                                draggable="false" className="class_webplay_btnicon_ios" src={web_play_btn_icon} alt="" />
                        </i>
                    </span>
                </div>
                <div style={iosImageDescStyle}>
                    <div style={{ display: `${showNoImageInfo}` }} >
                        <strong>INFORMATION:</strong> <Translate value="IMAGE_NOT_AVAILABLE" />
                    </div>
                    <div style={{ display: `${showSoundbtn}` }} >
                        <span className="spanExpiryDate"><Translate value="EXPIRY_DATE" /> : {dataExpiryDate}</span>
                    </div>
                </div>
            </div>
        }
        else if(isSamsungBrowser && fullBrowserVersion <13 ){
            return <div>
            <div className="picprop" style={normalImageDivstyle}  >
                <img className="baseImageSamsung" src={iconWebBaseImage} alt="" onContextMenuCapture={event => event.preventDefault()} />
                <img className="imageProperty" id="" alt=""
                    onLoad={this.handleImageLoaded.bind(this)} src={this.state.imageUrl} />
                <span style={{ display: `${showSoundbtn}` }}
                    onTouchStart={()=>this.handleOntouchStartPlayBtn()}
                    onTouchEnd={()=>this.playSound()}
                    onTouchMove={event => event.preventDefault()}
                    onMouseDown={()=>this.handleOntouchStartPlayBtn()}
                    onMouseUp={()=>this.playSound()}
                >
                    <i> <img style={{
                        display: `${showWebPlayBtnIcon}`, msTransform: "rotate(-90deg)",
                        WebkitTransform: " rotate(-90deg)", /* Safari 3-8 */
                        transform: "rotate(-90deg)",
                    }}
                        onContextMenuCapture={event => event.preventDefault()}
                        draggable="false" className="class_webplay_btnicon" src={web_play_btn_icon} alt="" />
                    </i>
                </span>
            </div>
            <div style={normalImageDescStyle}>
                <div style={{ display: `${showNoImageInfo}` }} >
                    <strong>INFORMATION:</strong> <Translate value="IMAGE_NOT_AVAILABLE" />
                </div>
                <div style={{ display: `${showSoundbtn}` }} >
                    <span className="spanExpiryDate"><Translate value="EXPIRY_DATE" /> : {dataExpiryDate}</span>
                </div>
            </div>
        </div >
        }
        else{
        return <div>
            <div className="picprop" style={normalImageDivstyle}  >
                <img className="baseImage" src={iconWebBaseImage} alt="" onContextMenuCapture={event => event.preventDefault()} />
                <img className="imageProperty" id="" alt=""
                    onLoad={this.handleImageLoaded.bind(this)} src={this.state.imageUrl} />
                <span style={{ display: `${showSoundbtn}` }}
                    onTouchStart={()=>this.handleOntouchStartPlayBtn()}
                    onTouchEnd={()=>this.playSound()}
                    onTouchMove={event => event.preventDefault()}
                    onMouseDown={()=>this.handleOntouchStartPlayBtn()}
                    onMouseUp={()=>this.playSound()}
                >
                    <i> <img style={{
                        display: `${showWebPlayBtnIcon}`, msTransform: "rotate(-90deg)",
                        WebkitTransform: " rotate(-90deg)", /* Safari 3-8 */
                        transform: "rotate(-90deg)",
                    }}
                        onContextMenuCapture={event => event.preventDefault()}
                        draggable="false" className="class_webplay_btnicon" src={web_play_btn_icon} alt="" />
                    </i>
                </span>
            </div>
            <div style={normalImageDescStyle}>
                <div style={{ display: `${showNoImageInfo}` }} >
                    <strong>INFORMATION:</strong> <Translate value="IMAGE_NOT_AVAILABLE" />
                </div>
                <div style={{ display: `${showSoundbtn}` }} >
                    <span className="spanExpiryDate"><Translate value="EXPIRY_DATE" /> : {dataExpiryDate}</span>
                </div>
            </div>
        </div >
        }
    }

    /**
     * Sound Cheki Password Protection Operations.
     */
    showModal = () => {
        this.setState({ visible: true });
    }

    handleCancel = () => {
        this.setState({ visible: false });
    }

    handleCreate = () => {
        const form = this.formRef.props.form;

        form.validateFields((err, values) => {
            if (err) {
                return;
            }
            this.handleGetQrCodeData(values.Password);

        });
    }

    saveFormRef = (formRef) => {
        this.formRef = formRef;
    }

    /**
     * Redirect to app if exist
     * or goto playstore / app store using firebase dynamic link.
     */
    handleOkAppRequiredModal = () => {

        const { Uid, imageUrl, audioUrl, isVideoDownloadCountExceeded, firebaseDynamicLink, isChinaConfig } = this.state;
        //on clicking ok button , disable the download button and dismiss the modal
        this.setState({
            isVideoButtonDisabled: true,
            isAppRequiredModalVisible: false
        })
        //if download count exceeded then show warning and exit
        if (isVideoDownloadCountExceeded) {
            return false;
        }

        //custom uri scheme for China
        if (isChinaConfig) {
            if (isAndroid) {
                //android browser compatible code  (including UC)  
                var CustomUriParams = "intent://https://instax.com?uid=" + Uid + "&imageUrl=" + encodeURIComponent(window.btoa(imageUrl)) + "&audioUrl=" + encodeURIComponent(window.btoa(audioUrl));
                window.location.replace(CustomUriParams + "#Intent;package=com.fujifilm.instaxminiLiPlayChina;scheme=com.fujifilm.instaxminiliplaychina;end", "_blank");
            }
            else if (isIOS) {
                CustomUriParams = "https://instax.com?uid=" + Uid + "&imageUrl=" + encodeURIComponent(window.btoa(imageUrl)) + "&audioUrl=" + encodeURIComponent(window.btoa(audioUrl));
                document.location = firebaseDynamicLink + CustomUriParams;
            }
            return false;
        }

        //Uncomment for enabling dev dynamic link => for iOS
        // if (isIOS) {
        //     var devFirebaseDynamicLink = "https://instaxminiplaydev.page.link/?efr=1&ibi=com.trenser.Instax-Mini-Play&isi=1440150749&amv=9&apn=com.fujifilm.instaxminiLiPlayChina&link=";
        //     var dynamicLinkParams = "https://instax.com?uid%3D" + Uid + "%26imageUrl%3D" + encodeURIComponent(window.btoa(imageUrl)) + "%26audioUrl%3D" + encodeURIComponent(window.btoa(audioUrl));
        //     window.open(devFirebaseDynamicLink + dynamicLinkParams, "_blank");
        //     return false;
        // }
        //Uncomment when using production build, Dynamic Link in production is same for both iOS/andriod.
        var dynamicLinkParams = "https://instax.com?uid%3D" + Uid + "%26imageUrl%3D" + encodeURIComponent(window.btoa(imageUrl)) + "%26audioUrl%3D" + encodeURIComponent(window.btoa(audioUrl));
        window.open(firebaseDynamicLink + dynamicLinkParams, "_self");

        //Prod global  => https://instaxminiliplay.page.link/?efr=1&ibi=com.fujifilm.instaxminiLiPlay&isi=1460567208&amv=10&apn=com.fujifilm.instaxminiLiPlay&link=
        //Prod china   => https://instaxminiliplaychina.page.link/?efr=1&ibi=com.fujifilm.instaxminiLiPlayChina&isi=1460567773&amv=10&apn=com.fujifilm.instaxminiLiPlayChina&link=           
    }

    /**
     * If download Limit Exceeded then show warning msg.
     * If is Andriod/iOS show the App Required Modal. 
     */
    handleDownloadBtnClick = () => {

        //show warning message if other than iOS/Andriod.
        if (isIOS || isAndroid) {
            //show exceeded modal if download count exceeded. 
            if (this.state.isVideoDownloadCountExceeded) {
                this.setState({
                    isVideoDownloadExceedWarningVisible: this.state.isVideoDownloadCountExceeded,
                    isVideoButtonDisabled: true
                })
                return false;
            }
            //check if it is iOS or android platform.
            //then show the app required modal.

            this.setState({
                isVideoDownloadSupportedPlatform: true,
                isAppRequiredModalVisible: true
            });

        } else {
            this.setState({
                isVideoDownloadSupportedPlatform: false
            })
            return false;
        }
    }

    /**
     * Dismiss the app required modal.
     */
    handleCancelAppRequiredModal = () => {
        this.setState({ isAppRequiredModalVisible: false })
    }

    /**
     * Used to invoke password Modal.
     */
    renderPasswordPopUp = () => {
        if (this.state.visible) {
            return <PasswordPopUp
                wrappedComponentRef={this.saveFormRef}
                visible={this.state.visible}
                onCancel={this.handleCancel}
                onCreate={this.handleCreate}
                loading_passwordImage={this.state.loading_passwordImage}
                modalErrorMessage={this.state.modalErrorMessage}
                modalLoginFailureCount={this.state.modalLoginFailureCount}
                showRemainingAttempts={this.state.showRemainingAttempts}
            />
        }
    }

    /**
     * It is used to render App Required Modal,
     * When Clicking the Download Button 
     */
    renderAppRequiredModal = () => {
        const { isAppRequiredModalVisible, isChinaConfig } = this.state;
        if (isAppRequiredModalVisible) {
            return <ModalAppRequiredInfo
                isVisible={isAppRequiredModalVisible}
                handleOk={this.handleOkAppRequiredModal}
                handleCancel={this.handleCancelAppRequiredModal}
                isChinaWebApp={isChinaConfig}
            />
        }
    }

    /**
     * Used to render video dowmload info.
     */
    renderVideoDownloadExceededInfo = () => {
        const {
            isVideoDownloadExceedWarningVisible, topValueCenterContentOverlay,
            topValueDownloadButtonOverlay, downloadExceededDismissBtnStyle
        } = this.state;

        return <VideoDownloadExceededInfo
            isVisible={isVideoDownloadExceedWarningVisible} handleBackBtn={this.handleBackBtnDownloadExceededInfo}
            dismissBtnStyle={downloadExceededDismissBtnStyle} topValueOfCenterContent={topValueCenterContentOverlay}
            topValueOfDownloadButton={topValueDownloadButtonOverlay} />
    }

    /**
      * Get Overlay layout - Position
      * For child video download component..
      */
    setVideoDownloadExceededInfoStyle() {
        var bodyRect = document.body.getBoundingClientRect();
        var downloadBtnRect = ReactDOM.findDOMNode(this.downloadBtnRef.current).getBoundingClientRect();
        var offset = downloadBtnRect.top - bodyRect.top;

        const percentUptoOverlayCenterContent = (54) / 100;
        var topValueForCenterContent = offset * percentUptoOverlayCenterContent - downloadBtnRect.height;
        var dismissBtnWidth = downloadBtnRect.width;
        if (this.state.isChinaConfig && isAndroid) {
            // there is two buttons and spacing in the case of china : download & install
            dismissBtnWidth = downloadBtnRect.width * 2.1
        }
        const downloadExceededDismissStyle = {
            borderColor: "#857e6b",
            borderRadius: "8px",
            backgroundColor: "white",
            color: "#857e6b",
            padding: "0 39.6%",
            height: downloadBtnRect.height,
            width: dismissBtnWidth
        }

        this.setState({
            topValueDownloadButtonOverlay: offset,
            topValueCenterContentOverlay: topValueForCenterContent,
            downloadExceededDismissBtnStyle: downloadExceededDismissStyle
        });
    }

    /**
     * handle back button of the download exceeded info
     */
    handleBackBtnDownloadExceededInfo = () => {
        this.setState({ isVideoDownloadExceedWarningVisible: false });
    }

    /**
     * main rendering method.
     */
    render() {
        const { showMainContainer,
            showMainContainerLoading,
            web_icon_logo_instax,
            isVideoDownloadButtonVisible, isVideoButtonDisabled,
            isVideoDownloadSupportedPlatform, isChinaConfig
        } = this.state;

        return (
            <div>
                {this.renderVideoDownloadExceededInfo()}
                {
                    this.state.isServiceDown ? <ServiceNotAvilable /> :
                    <div className="mainDiv">
                    <div className="logoImageDivision" >
                        <img className="logoImage" alt="" onContextMenuCapture={event => event.preventDefault()} src={web_icon_logo_instax} />
                    </div>
                    <div className="mainContainer" >
                        <div style={{ display: `${showMainContainer}` }}>
                            <audio
                                onError={this.handleSoundFinishedPlaying}
                                onEnded={this.handleSoundFinishedPlaying}
                                id="id_audio" src={this.state.audioUrl} >
                            </audio>
                            <div>
                                {this.renderImageDivision()}
                            </div>
                            <DownloadBtnComponent handleDownloadBtnClick={this.handleDownloadBtnClick}
                                refDownloadBtn={this.downloadBtnRef} isVideoDownloadButtonVisible={isVideoDownloadButtonVisible}
                                isVideoButtonDisabled={isVideoButtonDisabled} isChinaConfig={isChinaConfig} />

                            <div style={{ display: `${isVideoDownloadSupportedPlatform ? "none" : ""}` }}>
                                <Alert
                                    description={<div className="warn-download-not-support">
                                        <Translate value="WARN_MSG_DOWNLOAD_NOT_SUPPORTED" />
                                    </div>}
                                    type="warning"
                                    closable
                                    showIcon
                                />
                            </div>
                            {this.renderAppRequiredModal()}
                            {this.renderPasswordPopUp()}
                        </div>
                        <div className="" style={{ display: `${showMainContainerLoading}`, }}>
                            <div style={{ paddingTop: "9px", flex: 1, justifyContent: "center", display: "flex" }}>
                                <Spin />
                            </div>
                        </div>
                    </div>
                </div >
                }
               
            </div>
        )
    }
}

/**
 * Password Prompt Modal
 */
const PasswordPopUp = Form.create()(
    class extends Component {
        render() {
            const { visible, onCancel, onCreate, form, loading_passwordImage,
                showRemainingAttempts,
                modalErrorMessage, modalLoginFailureCount } = this.props;
            const { getFieldDecorator } = form;
            return (
                <div style={{ minHeight: "200px" }}>
                    <Modal
                        visible={visible}
                        title={<Translate value="ENTERPASSWORD" />}
                        okText={<Translate value="SUBMIT" />}
                        cancelText={<Translate value="CANCEL" />}
                        onCancel={onCancel}
                        onOk={onCreate}
                        style={{ maxWidth: "350px" }}
                        maskClosable={false}
                        className="modal-password-prompt"
                    >
                        <Form layout="vertical">
                            <FormItem label={<Translate value="PASSWORD" />}>
                                {getFieldDecorator('Password', {
                                    rules: [{ required: true, message: <Translate value="INPUTPASSWORD" /> }],
                                })(
                                    <Input type="password" autoFocus="on" />
                                )}
                            </FormItem>
                            <div className="row">
                                <span style={{ color: "#dc3545", fontSize: "14px" }}><strong> {modalErrorMessage}</strong>
                                </span>
                            </div>
                            <div className="row">
                                <span style={{ display: showRemainingAttempts }}>
                                    <Translate value="REMAINIG_ATTEMPT" />.. {5 - modalLoginFailureCount}
                                </span>
                            </div>
                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <Spin spinning={loading_passwordImage} />
                            </div>
                        </Form>
                    </Modal>
                </div >
            );
        }
    }
);


export default QrCodeData;

