import React from 'react'
import Layout from "antd/lib/layout/layout";
const { Header, Content } = Layout;
var Translate = require("react-redux-i18n").Translate;

function ServiceNotAvilable() {
  return (
    <div
    style={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      width: "100%",
    }}
  >
    <Layout className="layout">
      <Header>
        <div className="logo" />
      </Header>
      <Content
        style={{
          padding: "0px 10px",
          flex: 1,
          backgroundColor: "#aliceblue",
        }}
      >
        <div className="row" style={{ margin: "16px 0" }}>
          <div className="col-lg-12">
            <div
              style={{
                minHeight: "600px",
                justifyContent: "center",
                alignContent: "center",
              }}
            >
              {" "}
              <br />
              <br />
              <span style={{ margin: "15px auto" }}>
                <span style={{ margin: "15px auto", fontSize: "20px" }}>
                  {navigator.language == "ja" ? (
                    <>
                      <Translate value="現在音チェキサービスをご利用できない状況となっております。" />
                      <br />
                      <Translate value="復旧次第、アプリへの通知および" />
                      <a
                        href={
                          "https://instax.jp/news/mini_liplay_20230718/"
                        }
                      >
                        Webページ
                      </a>
                      <Translate value="の情報更新により連絡させていただきます。" />
                    </>
                  ) : (
                    <>
                      Currently, there is a problem with the sound print
                      service.
                      <br />
                      As soon as it is restored, we will make an
                      announcement through a push notification and update
                      the information on
                      <a
                        href={
                          "https://instax.com/news/en/mini_liplay_20230718/"
                        }
                      >
                        {" "}
                        our web site.
                      </a>
                    </>
                  )}
                </span>
              </span>
            </div>
          </div>
        </div>
      </Content>
    </Layout>
  </div>
  )
}

export default ServiceNotAvilable