// ***********************************************************************
// Assembly         : FujiFilm.InstaxMini.UserWeb
// Author           : Pranav K
// Created          : 30-11-2018
// ***********************************************************************
// <copyright file="UnAuthorizedAcesss.js" company="Fuji Film Corporation">
//     Copyright ©  2018
// </copyright>
// <summary></summary>
// ***********************************************************************
import React, { Component } from 'react';
import Layout from 'antd/lib/layout/layout';
const { Header, Content } = Layout;

class UnAuthorizedAcesss extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            cardwidth: 800,
            contentPadding: '0px 150px'
        }
        console.log(this.props)
        console.log(this.props.match.params.uid)
    }

    componentDidMount() {
        this.screenWidthConfig();
    }
    /**
     * Adjust card width according to screen width.
     */
    screenWidthConfig = () => {
        const breakpoints = {
            desktop: 1040,
            tablet: 840,
            mobile: 540
        };
        if (window.innerWidth > breakpoints.mobile) {
            this.setState({ cardwidth: window.innerWidth - 50 })
            this.setState({ contentPadding: '0px 20px' })

        } else if (window.innerWidth <= breakpoints.mobile) {
            this.setState({ cardwidth: window.innerWidth - 50 })
            this.setState({ contentPadding: '0px 20px' })
        }
    }
    render() {
        const { contentPadding } = this.state;
        return (
            <div style={
                {
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    width: "100%"
                }
            }>
                <Layout className="layout">
                    <Header>
                        <div className="logo" />

                    </Header>
                    <Content style={{
                        padding: contentPadding,
                        flex: 1, backgroundColor: "#aliceblue",

                    }}>
                        <div className="row" style={{ margin: '16px 0' }}>
                            <div className="col-lg-12">
                                <div

                                    style={{ minHeight: "600px", justifyContent: "center", alignContent: "center" }}
                                > <br />
                                    <br />
                                    <span style={{ margin: "15px auto" }}>
                                        <span style={{ fontSize: "30px", color: "#dc3545" }}>Access Denied..!!</span>
                                        <br />
                                        <span style={{ margin: "15px auto", fontSize: "20px" }}>
                                            You are not authorized to view this page..!
                                    </span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </Content>


                </Layout>
            </div >

        )
    }

}
export default UnAuthorizedAcesss;