const translationsObject = {
    en: {
        IMAGE_NOT_AVAILABLE: "The image was not uploaded or has been deleted",
        ENTERPASSWORD: "Enter Password",
        INPUTPASSWORD: "Please input the pin/password ",
        PASSWORD: "Password",
        INCORRECTPASSWORD: "Incorrect Password",
        REMAINIG_ATTEMPT: "Remaining Attempts",
        SUBMIT: "SUBMIT",
        CANCEL: "CANCEL",
        MAX_TRY_EXCEEDED: "You have exceeded the maximum number of attempts, Please retry after some time..!",
        EXPIRY_DATE: "Browse period",
        BTN_DOWNLOAD_VIDEO: "Download",
        BTN_DOWNLOAD_VIDEO_CN: "Download",
        BTN_INSTALL_APP: "Install",
        APP_REQUIRED_INFO: `The free instax mini LiPlay app is required for downloading. \n` +
            `If the app is installed, it will start automatically and downloading will begin. \n` +
            `If the app is not installed, you will be taken to the App Store or Google Play Store, where you can download the app.`,
        APP_REQUIRED_INFO_CN: `The free instax mini LiPlay app is required for downloading. \n ` +
            `If the app is already installed, press “Download”. \n` +
            `If the app is not installed, press “Install”.`,
        APP_REQUIRED_BTN_OK: "OK",
        TITLE_DOWNLOAD_EXCEEDED: "You have reached the download limit.",
        SUBTITLE_DOWNLOAD_EXCEEDED: "Cannot download as there are no more downloads remaining.",
        BACK_BUTTON_TEXT_DOWNLOAD_EXCEEDED: "OK",
        WARN_MSG_DOWNLOAD_NOT_SUPPORTED: "The Android or iOS app is required to use the download function."
    },
    ja: {
        IMAGE_NOT_AVAILABLE: "画像がアップロードされていないか、削除されています。"
        , ENTERPASSWORD: "Enter Password",
        INPUTPASSWORD: "Please input the pin/password ",
        PASSWORD: "Password",
        INCORRECTPASSWORD: "Incorrect Password",
        REMAINIG_ATTEMPT: "Remaining Attempts",
        SUBMIT: "SUBMIT",
        CANCEL: "CANCEL",
        MAX_TRY_EXCEEDED: "You have exceeded the maximum number of attempts, Please retry after some time..!",
        EXPIRY_DATE: "閲覧期限",
        BTN_DOWNLOAD_VIDEO: "ダウンロード",
        BTN_DOWNLOAD_VIDEO_CN: "Download",
        BTN_INSTALL_APP: "Install",
        APP_REQUIRED_INFO: `ダウンロード機能を利用するためには"instax mini LiPlay"（無料アプリ）が必要です。\n` +
            `アプリがインストールされている場合は自動でアプリが起動し、ダウンロードが開始されます。\n` +
            `アプリがインストールされていない場合はアプリストアへ移動しますので、アプリをダウンロードしてください。`,
        APP_REQUIRED_INFO_CN: `ダウンロード機能を利用するためには"instax mini LiPlay"（無料アプリ）が必要です。\n` +
            `アプリがインストールされている場合は”Download"、\n ` +
            `アプリがインストールされていない場合は”Install”を押してください。`,
        APP_REQUIRED_BTN_OK: "OK",
        TITLE_DOWNLOAD_EXCEEDED: "ダウンロード上限回数に達しました。",
        SUBTITLE_DOWNLOAD_EXCEEDED: "ダウンロード上限回数に達しているためダウンロードできません。",
        BACK_BUTTON_TEXT_DOWNLOAD_EXCEEDED: "OK",
        WARN_MSG_DOWNLOAD_NOT_SUPPORTED: "ダウンロード機能を使うにはアプリはAndroid又はiOSのアプリが必要になります。"
    },
    fr: {
        IMAGE_NOT_AVAILABLE: "L’image n’a pas été téléversée ou a été supprimée."
        , ENTERPASSWORD: "Enter Password",
        INPUTPASSWORD: "Please input the pin/password ",
        PASSWORD: "Password",
        INCORRECTPASSWORD: "Incorrect Password",
        REMAINIG_ATTEMPT: "Remaining Attempts",
        SUBMIT: "SUBMIT",
        CANCEL: "CANCEL",
        MAX_TRY_EXCEEDED: "You have exceeded the maximum number of attempts, Please retry after some time..!",
        EXPIRY_DATE: "Période de navigation",
        BTN_DOWNLOAD_VIDEO: "Télécharger",
        BTN_DOWNLOAD_VIDEO_CN: "Télécharger",
        BTN_INSTALL_APP: "Installer",
        APP_REQUIRED_INFO: `L'application gratuite instax mini LiPlay est requise pour le téléchargement. \n` +
            `Si l'application est installée, elle démarre automatiquement et le téléchargement commence. \n ` +
            ` Si l'application n'est pas installée, vous serez dirigé vers l'App Store ou le Google Play Store, où vous pourrez télécharger l'application.`,
        APP_REQUIRED_INFO_CN: `L'application gratuite instax mini LiPlay est requise pour le téléchargement. \n` +
            `Si l'application est déjà installée, cliquez sur « Télécharger ». \n` +
            `Si l'application n'est pas installée, cliquez sur « Installer ».`,
        APP_REQUIRED_BTN_OK: "OK",
        TITLE_DOWNLOAD_EXCEEDED: "Vous avez atteint la limite de téléchargement.",
        SUBTITLE_DOWNLOAD_EXCEEDED: "Téléchargement impossible, car il ne reste plus de téléchargements.",
        BACK_BUTTON_TEXT_DOWNLOAD_EXCEEDED: "OK",
        WARN_MSG_DOWNLOAD_NOT_SUPPORTED: "L'application Android ou iOS est nécessaire pour utiliser la fonction de téléchargement."
    },
    de: {
        IMAGE_NOT_AVAILABLE: "Das Bild wurde nicht hochgeladen oder wurde gelöscht."
        , ENTERPASSWORD: "Enter Password",
        INPUTPASSWORD: "Please input the pin/password ",
        PASSWORD: "Password",
        INCORRECTPASSWORD: "Incorrect Password",
        REMAINIG_ATTEMPT: "Remaining Attempts",
        SUBMIT: "SUBMIT",
        CANCEL: "CANCEL",
        MAX_TRY_EXCEEDED: "You have exceeded the maximum number of attempts, Please retry after some time..!",
        EXPIRY_DATE: "Zu durchsuchender Zeitraum",
        BTN_DOWNLOAD_VIDEO: "Download",
        BTN_DOWNLOAD_VIDEO_CN: "Download",
        BTN_INSTALL_APP: "Installieren",
        APP_REQUIRED_INFO: `Zum Herunterladen wird die kostenlose „instax mini LiPlay“-App benötigt. \n ` +
            `Wenn die App installiert ist, startet sie automatisch und der Download beginnt. \n ` +
            `Wenn die App nicht installiert ist, werden Sie zum App Store oder Google Play Store weitergeleitet, wo Sie die App herunterladen können.`,
        APP_REQUIRED_INFO_CN: `Zum Herunterladen wird die kostenlose „instax mini LiPlay“-App benötigt. \n` +
            `Wenn die App bereits installiert ist, klicken Sie auf „Download“. \n` +
            `Wenn die App nicht installiert ist, klicken Sie auf „Installieren“.`,
        APP_REQUIRED_BTN_OK: "OK",
        TITLE_DOWNLOAD_EXCEEDED: "Sie haben das Download-Limit erreicht.",
        SUBTITLE_DOWNLOAD_EXCEEDED: "Herunterladen nicht möglich, da keine Downloads mehr übrig sind.",
        BACK_BUTTON_TEXT_DOWNLOAD_EXCEEDED: "OK",
        WARN_MSG_DOWNLOAD_NOT_SUPPORTED: "Um die Download-Funktion verwenden zu können, ist die Android- oder iOS-App erforderlich."
    },
    es: {
        IMAGE_NOT_AVAILABLE: "La imagen no se ha cargado o se ha eliminado."
        , ENTERPASSWORD: "Enter Password",
        INPUTPASSWORD: "Please input the pin/password ",
        PASSWORD: "Password",
        INCORRECTPASSWORD: "Incorrect Password",
        REMAINIG_ATTEMPT: "Remaining Attempts",
        SUBMIT: "SUBMIT",
        CANCEL: "CANCEL",
        MAX_TRY_EXCEEDED: "You have exceeded the maximum number of attempts, Please retry after some time..!",
        EXPIRY_DATE: "Examinar periodo",
        BTN_DOWNLOAD_VIDEO: "Descargar",
        BTN_DOWNLOAD_VIDEO_CN: "Descargar",
        BTN_INSTALL_APP: "Instalar",
        APP_REQUIRED_INFO: `Se requiere la aplicación instax mini LiPlay para realizar la descarga. \n` +
            `Si está instalada, la aplicación se iniciará automáticamente y comenzará la descarga. \n` +
            `Si la aplicación no está instalada, serás dirigido a App Store o Google Play Store, donde puedes descargar la aplicación.`,
        APP_REQUIRED_INFO_CN: `Se requiere la aplicación instax mini LiPlay gratuita para realizar la descarga. \n` +
            `Si la aplicación ya está instalada, pulsa “Descargar”.\n` +
            `Si la aplicación no está instalada, pulsa “Instalar”.`,
        APP_REQUIRED_BTN_OK: "Aceptar",
        TITLE_DOWNLOAD_EXCEEDED: "Has alcanzado el límite de descarga.",
        SUBTITLE_DOWNLOAD_EXCEEDED: "No es posible descargar porque no queda ninguna descarga.",
        BACK_BUTTON_TEXT_DOWNLOAD_EXCEEDED: "Aceptar",
        WARN_MSG_DOWNLOAD_NOT_SUPPORTED: "Se requiere la aplicación Android o iOS para usar la función de descarga."
    },
    'zh-cn': { //Simplified Chinese
        IMAGE_NOT_AVAILABLE: "图片未上传或已被删除。",
        ENTERPASSWORD: "输入密码",
        INPUTPASSWORD: "请输入pin码/密码",
        PASSWORD: "密码",
        INCORRECTPASSWORD: "密码不正确",
        REMAINIG_ATTEMPT: "剩余可输入次数",
        SUBMIT: "提交",
        CANCEL: "取消",
        MAX_TRY_EXCEEDED: "You have exceeded the maximum number of attempts, Please retry after some time..!",
        EXPIRY_DATE: "浏览期间",
        BTN_DOWNLOAD_VIDEO: "下载",
        BTN_DOWNLOAD_VIDEO_CN: "下载",
        BTN_INSTALL_APP: "安装",
        APP_REQUIRED_INFO: `需要instax mini LiPlay应用程序（免费）才能下载。 \n` +
            `如果安装了该应用程序，将自动启动并开始下载。 \n` +
            `如果未安装该应用程序，会将您转到App Store或Google Play Store，您可以在其中下载该应用程序。`,
        APP_REQUIRED_INFO_CN: `需要instax mini LiPlay应用程序（免费）才能下载。 \n` +
            `如果您已经安装了该应用程序，将自动启动并开始下载。\n ` +
            `如果您尚未安装该应用程序，请点击“安装”按钮，进行下载。`,
        APP_REQUIRED_BTN_OK: "确定",
        TITLE_DOWNLOAD_EXCEEDED: "您已达到下载次数上限。",
        SUBTITLE_DOWNLOAD_EXCEEDED: "无法下载，因为没有更多剩余下载次数。",
        BACK_BUTTON_TEXT_DOWNLOAD_EXCEEDED: "确定",
        WARN_MSG_DOWNLOAD_NOT_SUPPORTED: "使用下载功能需要Android或iOS应用程序。"
    },
    'zh-hk': { //Traditional Chinese
        IMAGE_NOT_AVAILABLE: "圖片未上傳或已被刪除。",
        ENTERPASSWORD: "輸入密碼",
        INPUTPASSWORD: "請輸入 PIN 碼/密碼",
        PASSWORD: "密碼",
        INCORRECTPASSWORD: "密碼錯誤",
        REMAINIG_ATTEMPT: "剩餘的嘗試次數",
        SUBMIT: "提交",
        CANCEL: "取消",
        MAX_TRY_EXCEEDED: "You have exceeded the maximum number of attempts, Please retry after some time..!",
        EXPIRY_DATE: "瀏覽期間",
        BTN_DOWNLOAD_VIDEO: "下載",
        BTN_DOWNLOAD_VIDEO_CN: "下載",
        BTN_INSTALL_APP: "安裝",
        APP_REQUIRED_INFO: `需要安裝免費版 instax mini LiPlay 應用程式才能下載。\n` +
            `如果安裝了應用程式，應用程式將自動啟動並開始下載。 \n` +
            `如果未安裝應用程式，系統將會引導您到 App Store 或 Google Play 商店下載該應用程式。`,
        APP_REQUIRED_INFO_CN: `需要安裝免費版 instax mini LiPlay 應用程式才能下載。 \n` +
            `如果已安裝應用程式，請按「下載」。\n` +
            `如果未安裝應用程式，請按「安裝」。`,
        APP_REQUIRED_BTN_OK: "確定",
        TITLE_DOWNLOAD_EXCEEDED: "您已達到下載限制。",
        SUBTITLE_DOWNLOAD_EXCEEDED: "無法下載，因為沒有其他剩餘的檔案要下載。",
        BACK_BUTTON_TEXT_DOWNLOAD_EXCEEDED: "確定",
        WARN_MSG_DOWNLOAD_NOT_SUPPORTED: "您需要 Android 或 iOS 應用程式才能使用下載功能。"
    },
    'zh-tw': { //Traditional Chinese
        IMAGE_NOT_AVAILABLE: "圖片未上傳或已被刪除。",
        ENTERPASSWORD: "輸入密碼",
        INPUTPASSWORD: "請輸入 PIN 碼/密碼",
        PASSWORD: "密碼",
        INCORRECTPASSWORD: "密碼錯誤",
        REMAINIG_ATTEMPT: "剩餘的嘗試次數",
        SUBMIT: "提交",
        CANCEL: "取消",
        MAX_TRY_EXCEEDED: "You have exceeded the maximum number of attempts, Please retry after some time..!",
        EXPIRY_DATE: "瀏覽期間",
        BTN_DOWNLOAD_VIDEO: "下載",
        BTN_DOWNLOAD_VIDEO_CN: "下載",
        BTN_INSTALL_APP: "安裝",
        APP_REQUIRED_INFO: `需要安裝免費版 instax mini LiPlay 應用程式才能下載。\n` +
            `如果安裝了應用程式，應用程式將自動啟動並開始下載。 \n` +
            `如果未安裝應用程式，系統將會引導您到 App Store 或 Google Play 商店下載該應用程式。`,
        APP_REQUIRED_INFO_CN: `需要安裝免費版 instax mini LiPlay 應用程式才能下載。 \n` +
            `如果已安裝應用程式，請按「下載」。\n` +
            `如果未安裝應用程式，請按「安裝」。`,
        APP_REQUIRED_BTN_OK: "確定",
        TITLE_DOWNLOAD_EXCEEDED: "您已達到下載限制。",
        SUBTITLE_DOWNLOAD_EXCEEDED: "無法下載，因為沒有其他剩餘的檔案要下載。",
        BACK_BUTTON_TEXT_DOWNLOAD_EXCEEDED: "確定",
        WARN_MSG_DOWNLOAD_NOT_SUPPORTED: "您需要 Android 或 iOS 應用程式才能使用下載功能。"
    },

    ko: {
        IMAGE_NOT_AVAILABLE: "이미지가 업로드되지 않았거나 삭제되었습니다.",
        ENTERPASSWORD: "Enter Password",
        INPUTPASSWORD: "Please input the pin/password ",
        PASSWORD: "Password",
        INCORRECTPASSWORD: "Incorrect Password",
        REMAINIG_ATTEMPT: "Remaining Attempts",
        SUBMIT: "SUBMIT",
        CANCEL: "CANCEL",
        MAX_TRY_EXCEEDED: "You have exceeded the maximum number of attempts, Please retry after some time..!",
        EXPIRY_DATE: "검색 기간",
        BTN_DOWNLOAD_VIDEO: "다운로드",
        BTN_DOWNLOAD_VIDEO_CN: "다운로드",
        BTN_INSTALL_APP: "설치",
        APP_REQUIRED_INFO: `무료 instax mini LiPlay 앱이 있어야 다운로드 가능합니다. \n` +
            `앱이 설치된 경우, 자동으로 앱이 시작되어 다운로드를 개시합니다. \n` +
            `앱이 설치되지 않은 경우, 앱을 다운로드하실 수 있도록 App Store나 Google Play Store로 이동합니다.`,
        APP_REQUIRED_INFO_CN: `무료 instax mini LiPlay 앱이 있어야 다운로드 가능합니다. \n` +
            `앱을 이미 설치한 경우 “다운로드”를 누르십시오. \n` +
            `앱이 설치되지 않은 경우 “설치”를 누르십시오.`,
        APP_REQUIRED_BTN_OK: "OK",
        TITLE_DOWNLOAD_EXCEEDED: "다운로드 한도에 도달했습니다.",
        SUBTITLE_DOWNLOAD_EXCEEDED: "다운로드 한도에 도달해 더 이상 다운로드할 수 없습니다.",
        BACK_BUTTON_TEXT_DOWNLOAD_EXCEEDED: "OK",
        WARN_MSG_DOWNLOAD_NOT_SUPPORTED: "Android 또는 iOS 앱이 있어야 다운로드 기능을 사용할 수 있습니다."
    },
    pt: {
        IMAGE_NOT_AVAILABLE: "A imagem não foi carregada ou foi apagada.",
        ENTERPASSWORD: "Enter Password",
        INPUTPASSWORD: "Please input the pin/password ",
        PASSWORD: "Password",
        INCORRECTPASSWORD: "Incorrect Password",
        REMAINIG_ATTEMPT: "Remaining Attempts",
        SUBMIT: "SUBMIT",
        CANCEL: "CANCEL",
        MAX_TRY_EXCEEDED: "You have exceeded the maximum number of attempts, Please retry after some time..!",
        EXPIRY_DATE: "Tempo de navegação",
        BTN_DOWNLOAD_VIDEO: "Download",
        BTN_DOWNLOAD_VIDEO_CN: "Download",
        BTN_INSTALL_APP: "Instalar",
        APP_REQUIRED_INFO: `É necessário ter o aplicativo gratuito instax mini LiPlay para baixar. \n` +
            `Se o aplicativo estiver instalado, ele será iniciado automaticamente e o download começará. \n` +
            `Se o aplicativo não estiver instalado, você será levado à Loja de Aplicativos ou à Loja do Google Play, onde poderá baixar o aplicativo.`,
        APP_REQUIRED_INFO_CN: `É necessário ter o aplicativo gratuito instax mini LiPlay para baixar. \n ` +
            `Se o aplicativo já estiver instalado, pressione "Download". \n` +
            `Se o aplicativo não estiver instalado, pressione "Instalar".`,
        APP_REQUIRED_BTN_OK: "OK",
        TITLE_DOWNLOAD_EXCEEDED: "Você atingiu o limite de downloads.",
        SUBTITLE_DOWNLOAD_EXCEEDED: "Não é possível baixar porque não há mais downloads disponíveis.",
        BACK_BUTTON_TEXT_DOWNLOAD_EXCEEDED: "OK",
        WARN_MSG_DOWNLOAD_NOT_SUPPORTED: "O aplicativo para Android ou iOS é necessário para utilizar a função de download."
    },
    it: {
        IMAGE_NOT_AVAILABLE: "L'immagine non è stata caricata o è stata eliminata",
        ENTERPASSWORD: "Inserisci la password",
        INPUTPASSWORD: "Si prega di inserire il pin/password",
        PASSWORD: "Password",
        INCORRECTPASSWORD: "Password errata",
        REMAINIG_ATTEMPT: "Tentativi rimanenti",
        SUBMIT: "INVIA",
        CANCEL: "ANNULLA",
        MAX_TRY_EXCEEDED: "Hai superato il numero massimo di tentativi, si prega di riprovare dopo un po'!",
        EXPIRY_DATE: "Periodo di navigazione",
        BTN_DOWNLOAD_VIDEO: "Scarica",
        BTN_DOWNLOAD_VIDEO_CN: "Scarica",
        BTN_INSTALL_APP: "Installazione",
        APP_REQUIRED_INFO: `È necessario installare l'app gratuita instax mini LiPlay per il download. \n` +
            `Se l'app è già installata, si avvierà automaticamente e inizierà il download. \n` +
            `Se l'app non è installata, verrai indirizzato a App Store o Google Play Store, dove potrai scaricare l'app.`,
        APP_REQUIRED_INFO_CN: `È necessario installare l'app gratuita instax mini LiPlay per il download. \n ` +
            `Se l'app è già installata, premi "Download". \n` +
            `Se l'app non è installata, premi "Installazione".`,
        APP_REQUIRED_BTN_OK: "OK",
        TITLE_DOWNLOAD_EXCEEDED: "Hai raggiunto il limite di download.",
        SUBTITLE_DOWNLOAD_EXCEEDED: "Impossibile effettuare il download poiché non sono rimasti altri download.",
        BACK_BUTTON_TEXT_DOWNLOAD_EXCEEDED: "OK",
        WARN_MSG_DOWNLOAD_NOT_SUPPORTED: "Per utilizzare la funzione di download è necessaria l'app per Android o iOS."
    }
};


export default translationsObject;