// ***********************************************************************
// Assembly         : FujiFilm.InstaxMini.UserWeb
// Author           : Pranav K
// Created          : 30-11-2018
// ***********************************************************************
// <copyright file="Home.js" company="Fuji Film Corporation">
//     Copyright ©  2018
// </copyright>
// <summary></summary>
// ***********************************************************************
import React, { Component } from 'react';
import Layout from 'antd/lib/layout/layout';
import GlobalStrings from "../network/Global";
import axios from 'axios';
import APIHandler from '../network/AxiosInstance';
const { Header } = Layout;

/**
 * Sample HomePage
 * 
 */
class Home extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            cardwidth: 800,
            verifiedIp: false,
            currentPage: window.location.href.split("://")[1]
        }
    }

    componentWillMount() {

        //Check whether country restriction active or not.
        axios.get('config.json').then((res) => {
            var countrywiseRestrictionChina = res.data.countrywiseRestrictionInChina;
            var countrywiseRestrictionGlobal = res.data.countrywiseRestrictionInGlobal;
            // eslint-disable-next-line
            if (countrywiseRestrictionChina == "true") {
                this.handleCountrywiseRestriction();
            }
            // eslint-disable-next-line 
            else if (countrywiseRestrictionGlobal == "true") {
                this.handleCountryRestrictionForGlobal();
            }
            else {
                this.setState({ verifiedIp: true })
            }
        });

    }

    /**
     * IP Restriction Part
     */
    handleCountrywiseRestriction() {

        APIHandler.AxiosInstance.post(GlobalStrings.WEB_API.WEB_CHECK_IPORIGIN_COUNTRY)
            .then(response => {
                console.log(response);

                if (response.data.status === 0) {
                    this.setState({ verifiedIp: true })
                }

                else {
                    this.setState({ verifiedIp: false })
                    this.props.history.push(GlobalStrings.URL_STRING.ACCESS_DENIED);
                }
            });
    }

    handleCountryRestrictionForGlobal() {
        APIHandler.AxiosInstance.post(GlobalStrings.WEB_API.WEB_CHECK_IPFOR_GLOBALSITES)
            .then(response => {

                console.log(response);
                if (response.data.status === 0) {
                    this.setState({ verifiedIp: true });
                }
                else {
                    this.setState({ verifiedIp: false })
                    this.props.history.push(GlobalStrings.URL_STRING.ACCESS_DENIED);
                }
            });
    }


    render() {
        const { verifiedIp, currentPage } = this.state;
        if (verifiedIp) {
            return (
                <div style={
                    {
                        flex: 1,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        width: "100%"
                    }
                }>
                    <Layout className="layout">
                        <Header>
                        </Header>
                        <div style={{ margin: '16px 0', fontFamily: "", flex: 1, justifyContent: "center", display: "flex" }}>
                            <br />
                            <h3>{currentPage}</h3>
                        </div>
                    </Layout>
                </div >)
        } else {
            return (<div></div>)
        }
    }
}
export default Home;