import React, { Component } from 'react'
import { Button } from 'antd';
import { isAndroid, isIOS } from 'react-device-detect';
import '../css/qrcodedata.css';
var Translate = require('react-redux-i18n').Translate;

const styles = {
    gb: {
        downloadBtn: {
            borderColor: "#857e6b",
            borderRadius: "6px",
            backgroundColor: "#f6f6f6",
            color: "#857e6b",
            width: "59.8%",
            height: "29pt"
        }
    },
    cn: {
        downloadBtn: {
            borderColor: "#857e6b",
            borderRadius: "6px",
            backgroundColor: "#f6f6f6",
            color: "#857e6b",
            padding: "2pt",
            height: "27pt"
        },
        installBtn: {
            borderColor: "#857e6b",
            borderRadius: "6px",
            backgroundColor: "#f6f6f6",
            color: "#857e6b",
            padding: "2pt",
            height: "27pt"
        }
    }
}
const CHINA_APK_DOWNLOAD_URL = "https://www.fujifilm.com.cn/instax/apk/instax_mini_liplay_for_android.html";
const CHINA_IOS_DOWNLOAD_URL = "https://apps.apple.com/cn/app/instax-mini-liplay-for-china/id1460567773";
class DownloadBtnComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    handleOnClickInstall = () => {
        window.open(CHINA_APK_DOWNLOAD_URL, "_blank");
    }

    handleOnClickInstalliOS = () => {
        document.location = CHINA_IOS_DOWNLOAD_URL;
    }
    renderDownloadButtonDiv = () => {
        const {
            isVideoDownloadButtonVisible, isVideoButtonDisabled, isChinaConfig,
            handleDownloadBtnClick, refDownloadBtn } = this.props;
        if (isChinaConfig && isAndroid) {
            return <div className="downloadBtnDiv" style={{ display: `${isVideoDownloadButtonVisible}`, margin: "0.5rem auto", textAlign: "center" }}>
                <span className="wrap-download-install-btn">
                    <Button className="downloadBtn download-install-btn-width" disabled={isVideoButtonDisabled} ref={refDownloadBtn}
                        style={styles.cn.downloadBtn} onClick={handleDownloadBtnClick}>
                        <span>  <Translate value="BTN_DOWNLOAD_VIDEO_CN" /> </span>
                    </Button>
                    <span style={{ padding: "2pt" }}></span>
                    <Button style={styles.cn.installBtn} className="download-install-btn-width"
                        onClick={this.handleOnClickInstall}>
                        <span>  <Translate value="BTN_INSTALL_APP" /> </span>
                    </Button>
                </span>
            </div>
        }
        //for using custom uri scheme
        else if (isChinaConfig && isIOS) {
            return <div className="downloadBtnDiv" style={{ display: `${isVideoDownloadButtonVisible}`, margin: "0.5rem auto", textAlign: "center" }}>
                <span className="wrap-download-install-btn">
                    <Button className="downloadBtn download-install-btn-width" disabled={isVideoButtonDisabled} ref={refDownloadBtn}
                        style={styles.cn.downloadBtn} onClick={handleDownloadBtnClick}>
                        <span>  <Translate value="BTN_DOWNLOAD_VIDEO_CN" /> </span>
                    </Button>
                    <span style={{ padding: "2pt" }}></span>
                    <Button style={styles.cn.installBtn} className="downloadBtn download-install-btn-width"
                        onClick={this.handleOnClickInstalliOS}>
                        <span> <Translate value="BTN_INSTALL_APP" />  </span>
                    </Button>
                </span>
            </div>
        }
        //global
        else {
            return <div className="downloadBtnDiv" style={{ display: `${isVideoDownloadButtonVisible}`, margin: "0.5rem auto", textAlign: "center" }}>
                <Button className="downloadBtn" disabled={isVideoButtonDisabled} ref={refDownloadBtn}
                    style={styles.gb.downloadBtn} onClick={handleDownloadBtnClick}><span>  <Translate value="BTN_DOWNLOAD_VIDEO" /> </span>
                </Button>
            </div>
        }

    }
    render() {
        return (<div>
            {this.renderDownloadButtonDiv()}
        </div>)
    }

}
export default DownloadBtnComponent