// ***********************************************************************
// Assembly         : FujiFilm.InstaxMini.AdminUI
// Author           : Pranav K
// Created          : 23-10-2018
//
// Last Modified By : Pranav K
// Last Modified On : 23-10-2018
// ***********************************************************************
// <copyright file="index.js" company="Fuji Film Corporation">
//     Copyright ©  2018
// </copyright>
// <summary></summary>
// ***********************************************************************
import React from 'react';
import ReactDOM from 'react-dom';
import './css/index.css';
// import './../node_modules/bootstrap/dist/css/bootstrap.min.css';
// import '../node_modules/font-awesome/css/font-awesome.min.css';
import App from './App';
import i18nStrings from './network/Lang.js'
import './../node_modules/antd/lib/modal/style/css';
import './../node_modules/antd/lib/form/style/css';
import './../node_modules/antd/lib/input/style/css';
import './../node_modules/antd/lib/spin/style/css';
import './../node_modules/antd/lib/layout/style/css';
import './../node_modules/antd/lib/alert/style/css';
import { BrowserRouter } from "react-router-dom"
import * as serviceWorker from './serviceWorker';
import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { loadTranslations, setLocale, syncTranslationWithStore, i18nReducer } from 'react-redux-i18n';
import reducers from './reducers/i18n';
//const langArray = ['en', 'ja', 'fr', 'de', 'es', 'ko', 'pt', 'zh-CN', 'zh-TW', 'zh-HK'];

const translationsObject = i18nStrings;

const store = createStore(
    combineReducers({
        ...reducers,
        i18n: i18nReducer
    }),
    applyMiddleware(thunk)
);
syncTranslationWithStore(store)
store.dispatch(loadTranslations(translationsObject));

const language = (navigator.languages && navigator.languages[0]) ||
    navigator.language || navigator.userLanguage;
store.dispatch(setLocale(language.toLowerCase()));


ReactDOM.render(

    <BrowserRouter><App /></BrowserRouter>
    , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
